html {
  --headerBgc: #f2e8ad;
  --displayBgc: #850a00;
  --mainBgc: #850a00;
  --buttonBgc: #f28241;
  --textColor: #ffe78c;
  --typesButtonBgc: #ffe78c;
  --typesButtonColor: #850a00;
  --typesButtonSelectedBgc: #b11a02;
  --typesButtonSelectedColor: #ffffff;
  --couponCatBgc: #f2e8ad;
  --couponCatColor: #850b01;
  --couponCatSelectedBgc: #b11a02;
  --couponCatSelectedColor: #ffffff;
  --buttonSuccess: #198754;
  --buttonFail: #b11a02;
  --ballBgc: #f2e8ad;
  --ballColor: #850b01;
  --couponBgc: #b11a02;
  --couponBorder: #f2e8ad;
  --lineColor: #23b900;
  --buttonRules: #b11a02;
  --diffOdds: #ff9614;
  --bodyTextColor: #ffe78c;
}
* {
  margin: 0;
  padding: 0;
  font-family: "Noto Sans Thai", sans-serif;
  box-sizing: border-box;
  user-select: none;
}
body {
  background-color: var(--mainBgc);
  color: var(--bodyTextColor);
}
div.app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  overflow: hidden;
}
header {
  flex-basis: 10vh;
  line-height: 10vh;
  text-align: center;
  letter-spacing: 1px;
  background-color: var(--headerBgc);
  color: var(--couponBgc);
  border-radius: 0 0 10px 10px;
  border-bottom: 3px solid var(--couponBgc);
}
main {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  text-align: center;
  background-color: var(--mainBgc);
}
div.display {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
  height: 10vh;
  text-align: center;
  font-size: 25px;
  background-color: var(--displayBgc);
  margin-top: 10px;
  font-weight: bold;
}
main.line {
  flex-grow: 0;
  flex-direction: column;
}
main.line img.logo{
  width:84px;
  margin:0 auto;
}
div.ball {
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 250%;
  width: 40px;
  height: 40px;
  border: 2px solid var(--ballBgc);
  border-radius: 50%;
  font-size: 22px;
  color: var(--ballColor);
  background-color: var(--ballBgc);
  transition: background-color 0.2s;
  transform: translateY(-200vh);
  animation: ballFalls 0.6s both;
}
div.ball.blocked {
  opacity: 0.2;
}
div.ball.blocked.odds {
  opacity: 0.7;
}
div.ball.three {
  font-size:18px;
}
@keyframes ballFalls {
  100% {
    transform: translateY(0);
  }
}
div.ball:nth-of-type(n) {
  transform: translateY(-200vh);
}
button.category {
  color: var(--couponCatColor);
  background-color: var(--couponCatBgc);
  margin:5px 5px;
  text-shadow:none;
  flex:17%;
}
button.category.selected {
  color: var(--couponCatSelectedColor);
  background-color: var(--couponCatSelectedBgc);
}
.coupon-categories {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-content: space-around;
  width: 90vw;
  max-width: 500px;
  margin-top:20px;
}
.coupon {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-content: space-around;
  margin: 20px auto;
  margin-bottom: 35px;
  width: 90vw;
  max-width: 500px;
  padding: 5px;
  background-color: var(--couponBgc);
  border: 2px solid var(--couponBorder);
  border-radius: 10px;
  box-shadow: 0 0 0 8px var(--couponBgc), 0 0 0px 10px var(--couponBgc);
}

.coupon.three .number{
  flex-basis: 19%;
}

.coupon.two .number {
  flex-basis: 14%;
  margin: 8px 8px;
}

.number {
  flex-basis: 9%;
  height: 30px;
  border-radius: 5px;
  line-height: 25px;
  color: #ffffff;
  cursor: pointer;
  font-family: monospace;
  font-size: 22px;
  margin: 8px 1px;
}

/*.number {
  flex-basis: 11%;
  height: 30px;
  border-radius: 5px;
  line-height: 25px;
  color: var(--couponBorder);
  cursor: pointer;
  font-family: monospace;
  font-size: 22px;
  margin: 8px -3px;
}*/

.number.selected {
  color: var(--couponBorder);
  border: 2px solid;
  line-height: 27px;
}

.number.blocked {
  text-decoration: line-through;
}
section.line {
  margin-bottom: 10px;
}
section.bets {
  width: 90vw;
  margin: 0 auto;
  max-width: 510px;
  margin-bottom: 30px;
}
.bets .info {
  margin-bottom: 10px;
  padding: 5px;
  border-radius: 1px 1px 10px 10px;
  border-bottom: 2px solid var(--couponCatBgc);
  background-color: var(--couponCatBgc);
  color: var(--couponCatColor);;
}
.bets .bet {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 5px;
/*  border-bottom: 2px dashed #8a8c3b;*/
}
.bets .bet input{
  height: 40px;
  padding: 10px;
  margin-left: 6px;
}
.bets .bet .pay {
  font-size: 12px;
  text-align: right;
  width: 85px;
  margin-right: 4px;
  color: var(--textColor);
}

.bets .bet .pay.blocked {
  color: var(--couponBorder);
}

.bets .bet button.reset{
  margin: 0px;
}

.controls {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-content: space-around;
  width: 90vw;
  max-width: 500px;
  margin: 0 auto;
}

.controls.default-stake {
  margin-top:10px;
  padding: 0 10px;
}

.controls.default-stake input{
  padding: 10px;
  flex-grow: 1;
  height:40px;
}

.footer {
  margin-top: -10px;
  margin-bottom:50px;
}

button {
  border-style: none;
  border-radius: 3px;
  outline: none;
  margin: 20px 10px;
  padding: 10px;
  text-transform: uppercase;
  text-shadow: 0 0 2px black;
  font-size: 15px;
  cursor: pointer;
  color: white;
  transition: 0.5s;
  animation: appearBtn 0.5s both;
}
.notiflix-report-message {
  white-space: pre-wrap;
}
button.rules {
  margin: 0 10px;
  margin-bottom: 10px;
  background-color: var(--buttonRules);
  flex-grow: 1;
  padding:5px;
}
button.type {
  text-shadow: none;
  color: var(--typesButtonColor);
  background-color: var(--typesButtonBgc);
  flex-grow: 1;
  flex:40%;
}
button.type.selected {
  color: var(--typesButtonSelectedColor);
  background-color: var(--typesButtonSelectedBgc);
}
button.sub-type {
  background-color: var(--couponCatBgc);
  flex-grow: 1;
  color: var(--couponCatColor);
  text-shadow:none;
}
button.sub-type.selected {
  color: var(--couponCatSelectedColor);
  background-color: var(--couponCatSelectedBgc);
}
button.sub-type.reverse {
  margin-bottom:0;
}
button.reset {
  color: var(--headerBgc);
  background-color: var(--buttonFail);
}
button.confirm {
  flex-grow: 1;
  background-color: var(--buttonSuccess);
}
button.line {
  background-color: var(--lineColor);
  flex-grow: 1;
  margin: 0 10px;
}
button.line span {
  margin-left: 10px;
  position: relative;
  top: -8px;
}
button.off {
  opacity: 0.5;
  background-color: gray;
}

@media (orientation: landscape) {
  header {
    flex-basis: 20vh;
    line-height: 20vh;
  }
  div.display {
    height: 20vh;
  }
  div.ball {
    width: 60px;
    height: 60px;
  }
  .coupon {
    width: 60vw;
  }
  section.results {
    width: 50vw;
  }
}
@media (min-width: 1024px) {
  div.app {
    width: 80vw;
    max-width: 1025px;
    margin: 0 auto;
  }
  header {
    flex-basis: 10vh;
    line-height: 10vh;
    opacity: 0.97;
  }
  main {
    border-radius: 10px 10px 0 0;
  }

  div.display {
    height: 10vh;
  }
}
